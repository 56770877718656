/* QuizForm.css */
.container {
  background-color: #333b65;
  color: white;
  /* background-image: url("./Assets/logo\ 1.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navbar {
  background-color: #f1f6fb;
  color: #333b65;
  text-align: center;
  font-size: 80px;
  padding: 10px;
  box-shadow: 0 15px 6px rgba(0, 0, 0, 0.1);
  /* font-family: "Foldit", sans-serif; */
  /* font-weight: 500; */
  font-family: "UnifrakturCook", cursive;
}
.subject {
  font-size: 45px;
  letter-spacing: 3px;
  margin-bottom: 20px;
  font-weight: 600;
}
.mcq-head-cont {
  /* width: 50vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
  font-size: 28px;
  letter-spacing: 1px;
}

.quiz-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 50px;
}
.question-container {
  width: 80vw;
  background-color: #f1f6fb;
  color: #020a32;
  padding: 10px;
  border-radius: 10px;
}
.question-text {
  font-size: 25px;
  font-weight: 500;
}
.choice-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.choice-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.quiz-form label {
  /* margin-bottom: 10px; */
  font-size: 20px;
}

.quiz-form input {
  margin: 5px 0;
  padding: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer; /* Add this line */
}

.quiz-form button {
  background-color: #ffc90b;
  color: #333b65;
  padding: 5px 10px;
  margin-top: 10px;
  font-size: 30px;
  min-width: 300px;
  margin-bottom: 100px;
  margin-top: 30px;
  box-shadow: 0 15px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out; /* Add transition property */
}
.quiz-form button:hover {
  transform: scale(1.2); /* Apply zoom effect on hover */
  cursor: pointer;
}

/* table */
.score {
  font-size: 30px;
  font-weight: bold;
  /* word-spacing: 5px; */
}
.suggested-program-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-top: 30px;
  letter-spacing: 1px;
  flex-direction: column;
  gap: 10px;
}
.table-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 50px;
}
.table {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 50vw;
  max-width: 80vw;
  font-size: 22px;
  text-align: center;

  /* box-shadow: 0 15px 6px rgba(0, 0, 0, 0.3); */

  /* margin-left: auto;
  margin-right: auto; */
}

/* row styling */
.row-container {
  display: flex;
  gap: 20px;
  background-color: #f1f6fb;
  padding: 10px;
  box-shadow: 0 15px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.row-container:hover {
  transform: scale(1.2);
  transition-delay: 0.2s;
  /* box-shadow: none; */
}
.row-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-image img {
  width: 200px;
}

.row-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: start;
  gap: 15px;
}

.row-title {
  font-family: "Times New Roman", Times, serif;
  color: #020a32;
  font-weight: 600;
}

.row-description {
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  text-align: start;
  color: #333b65;
  font-weight: bold;
  opacity: 0.8;
  font-family: "Times New Roman", Times, serif;
}
.row-others {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  gap: 40px;
}
.star-image {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.star-image img {
  width: 25px;
}
.star-image label {
  font-size: 20px;
  font-family: sans-serif;
  color: #020a32;
  font-weight: bold;
  opacity: 0.5;
}
.row-course-content {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}
.row-course-content img {
  width: 25px;
}

.row-course-content div {
  color: #020a32;
  font-family: "Times New Roman", Times, serif;
}

.start-course {
  color: #020a32;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* border: 1px solid black; */
  padding: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}
.start-course:hover {
  /* transform: scale(1.2); Apply zoom effect on hover */
  border: 1px solid black;
  cursor: pointer;
}

.completed {
  color: #020a32;
  display: flex;
  justify-content: center;
  align-items: center;
  word-spacing: 10px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.5;
}
